// Width sizes

$max-mobile-width: 768px;
$max-tablet-width: 1024px;

// Colors

$color-grey-0: #000000;
$color-grey-1: #0b0b0b;
$color-grey-2: #151515;
$color-grey-3: #202020;
$color-grey-4: #2b2b2b;
$color-grey-5: #353535;
$color-grey-6: #404040;
$color-grey-7: #4b4b4b;
$color-grey-8: #555555;
$color-grey-9: #606060;
$color-grey-10: #6b6b6b;
$color-grey-11: #757575;
$color-grey-12: #808080;
$color-grey-13: #8b8b8b;
$color-grey-14: #959595;
$color-grey-15: #a0a0a0;
$color-grey-16: #aaaaaa;
$color-grey-17: #b5b5b5;
$color-grey-18: #c0c0c0;
$color-grey-19: #cacaca;
$color-grey-20: #d5d5d5;
$color-grey-21: #dfdfdf;
$color-grey-22: #eaeaea;
$color-grey-23: #f4f4f4;
$color-grey-24: #ffffff;

$color-primary: #FF7A00;	// Main Primary color */
$color-primary-leftest: #FFAA5C;
$color-primary-left: #FF922E;
$color-primary-right: #D66700;
$color-primary-rightest: #994A00;

$color-secondary: #FFAE00;	// Main Secondary color (1) */
$color-secondary-leftest: #FFCB5C;
$color-secondary-left: #FFBD2E;
$color-secondary-right: #D69200;
$color-secondary-rightest: #A87300;

$color-ternary: #FF1300;	// Main Secondary color (2) */
$color-ternary-leftest: #FF685C;
$color-ternary-left: #FF3E2E;
$color-ternary-right: #D61000;
$color-ternary-rightests: #A80C00;

$color-link: $color-grey-6;
$color-link-hover: $color-grey-10;