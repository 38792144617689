#grav-login {
    line-height: 2.5rem;
    border: none !important;
    box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
                0 2px 4px rgba(0,0,0,0.07), 
                0 4px 8px rgba(0,0,0,0.07), 
                0 8px 16px rgba(0,0,0,0.07),
                0 16px 32px rgba(0,0,0,0.07); 

    .form-field {
        input {
            width: 90%;
            line-height: 2rem;
            padding-left: 1rem;
        }

        #g-recaptcha-user_register {
            display: flex;
            justify-content: center;
            margin: 2.5rem auto 1rem auto;
        }
    }

    .form-actions {
        display: flex;
        flex-direction: column;
        text-align: left !important;

        .primary {
            margin: 1rem auto;
            align-self: center;
            float: inline-start;
            width: 60%;
            cursor: pointer;
            padding: .5rem 1rem;
            border: 2px solid $color-primary;
            color: $color-grey-24;
            opacity: .8;
            background-color: $color-primary;
            font-weight: 900;
            border-radius: 3px;
            font-size: 1rem;
            transition: opacity 0.3s ease-in-out,
                        border 0.3s ease-in-out,
                        background-color 0.3s ease-in-out,
                        box-shadow .3s ease-in-out; 

            &:hover {
                opacity: 1;
                border: 2px solid $color-primary-leftest;
                background-color: $color-primary-leftest;
                box-shadow: 0 1px 1px rgba(0,0,0,0.06), 
                            0 2px 2px rgba(0,0,0,0.06), 
                            0 4px 4px rgba(0,0,0,0.06), 
                            0 8px 8px rgba(0,0,0,0.06);
            }
        }

        .secondary, label {
            font-size: .85rem;
        }

        .secondary {
            align-self: flex;
            order: 1;
            text-align: center;
        }
    }

    label {
        white-space: nowrap;
    }

    .buttons {
        height: 5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button[type=submit] {
            margin: 1rem auto;
            align-self: center;
            float: inline-start;
            width: 40%;
            min-width: min-content;
            cursor: pointer;
            padding: .5rem 1rem;
            border: 2px solid $color-primary;
            color: $color-grey-24;
            opacity: .8;
            background-color: $color-primary;
            font-weight: 900;
            border-radius: 3px;
            font-size: 1rem;
            transition: opacity 0.3s ease-in-out,
                        border 0.3s ease-in-out,
                        background-color 0.3s ease-in-out,
                        box-shadow .3s ease-in-out; 

            &:hover {
                opacity: 1;
                border: 2px solid $color-primary-leftest;
                background-color: $color-primary-leftest;
                box-shadow: 0 1px 1px rgba(0,0,0,0.06), 
                            0 2px 2px rgba(0,0,0,0.06), 
                            0 4px 4px rgba(0,0,0,0.06), 
                            0 8px 8px rgba(0,0,0,0.06);
            }
        }

        button[type=reset] {
            margin: 1rem auto;
            align-self: center;
            float: inline-start;
            width: 40%;
            min-width: min-content;
            cursor: pointer;
            padding: .5rem 1rem;
            border: 2px solid $color-primary-leftest;
            background-color: $color-grey-24;
            color: $color-primary-left !important;
            transition: border .3s ease-in-out, 
                        background-color .3s ease-in-out,
                        opacity .3s ease-in-out,
                        color .6s ease; 
            opacity: .8;
            font-weight: 900;
            border-radius: 3px;
            font-size: 1rem;

            &:hover {
                border: 2px solid $color-primary-left;
                background-color: $color-primary-leftest;
                opacity: .7;
                color: $color-grey-24 !important;
            }
        }

    }
}
@media only screen and (min-width: $max-mobile-width) {
    #grav-login {
        .form-field {
            input {
                width: 60%;
            }
        }

        .form-actions {
            align-items: center;
            .rememberme {
                width: 68%;
            }
        }
    }
}