.about-list {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    li {
        .about-tule {
            display: block;
            background-repeat: no-repeat;
            background-size: 100%;
            opacity: 1;
            height: 14rem;
            width: 14rem;
            margin-bottom: 2rem;
            margin-right: 2rem;
            border-radius: 6px;
            box-shadow: 0 0 1px rgba(0,0,0,0.05), 
                        0 0 2px rgba(0,0,0,0.05),
                        0 0 4px rgba(0,0,0,0.05), 
                        0 1px 1px rgba(0,0,0,0.05), 
                        0 2px 2px rgba(0,0,0,0.05), 
                        0 4px 4px rgba(0,0,0,0.05), 
                        0 8px 8px rgba(0,0,0,0.05);
            transition: box-shadow 0.3s ease-in-out;   
    
            &:hover {
                box-shadow: 0 0 1px rgba(0,0,0,0.11), 
                            0 0 2px rgba(0,0,0,0.11),
                            0 0 4px rgba(0,0,0,0.11), 
                            0 0 8px rgba(0,0,0,0.11), 
                            0 1px 1px rgba(0,0,0,0.11), 
                            0 2px 2px rgba(0,0,0,0.11), 
                            0 4px 4px rgba(0,0,0,0.11), 
                            0 8px 8px rgba(0,0,0,0.11), 
                            0 16px 16px rgba(0,0,0,0.11);
            
                > .about-tule-content {
                    opacity: .7;
                }
            }
    
            > .about-tule-content {
                height: 12rem;
                width: 12rem;
                padding: 1rem;
                opacity: .75;
                border-radius: 6px;
                background-color: $color-grey-5;
                transition: opacity .3s;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                line-height: 1rem;
                text-align: center;
    
                > .about-tule-title {
                    font-weight: 600;
                    margin: 0;
                    opacity: 1;
                    color: $color-primary-leftest;
                }
    
                > .about-tule-description {
                    line-height: initial;
                    font-size: 1.1em;
                    font-weight: 600;
                    opacity: 1;
                    color: $color-grey-24;                    
                }
            }
        }
    }

    &:last-child {
        margin-right: 0;
    }
}