$header-height: 2rem;
$header-padding: 1.5rem;

.header {
    visibility: visible;
    display: flex;
    justify-content: center;
    padding: $header-padding;
    border-top: 3px solid $color-primary;
    background-color: $color-grey-24;
    box-shadow: 0 1px 1px rgba(0,0,0,0.06), 
                0 2px 2px rgba(0,0,0,0.06), 
                0 4px 4px rgba(0,0,0,0.06), 
                0 8px 8px rgba(0,0,0,0.06);
    color: $color-grey-9;

    > ul {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: $header-height;
        margin: 0;
        align-items: center;
        list-style: none;
        width: 60rem;
        padding: 0;

        .header-link {
            color: $color-grey-10;
            transition: all .3s ease;
            i {
                transition: all .3s ease;
            }

            &:hover {
                color: $color-primary-leftest;
                border-bottom: 1px solid $color-primary;
                i {
                    color: $color-primary-leftest;
                }
            }
        }
    
        i {
            color: $color-grey-8;
            font-size: 1rem;
            transition: color .3s ease;

            &:hover {
                color: $color-primary-leftest;
            }
        }

        .menu-wrapper, .search-wrapper {
            display: initial;
            visibility: initial;
            position: absolute;
        }

        .logo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            color: $color-grey-9;
            text-decoration: none;

            h1 {
                color: $color-primary;
                border-bottom: 2.5px solid $color-grey-9;
                margin-bottom: -1rem;
                font-size: 1.5rem;


                span {
                    color: $color-grey-9;
                }
            }
            h2 {
                font-size: 1.2rem;
            }
        }

        .toggle {
            > i {
                font-size: 1.7rem;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .search-wrapper {
            display: none;
            z-index: 1;
            top: 0;
            left: 0;
            height: $header-height + (2 * $header-padding);
            width: 100%;
            border-top: 2px solid $color-primary;
            background-color: $color-grey-24;
            animation-name: slide-to-right;
            animation-duration: 1s;

            @keyframes slide-to-right {
                0%    { left:100vw; }
                100%  { left:0vw; }
            }

            form {
                height: $header-height + 2 * $header-padding;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                input {
                    width: 60vw;
                    line-height: 2.5rem;
                }

                .search-submit {
                    background: none;
                    border: none;
                    color: $color-grey-9;
                    margin-left: .5rem;
                    &:hover {
                        cursor: pointer;
                    }
                    > i {
                        font-size: 1.8rem;
                        margin-top: -.3rem;
                    }
                }
            }

            .search-toggle {
                position: absolute;
                right: 1.2rem;
                top: .6rem;
                color: $color-grey-9;

                &:hover {
                    cursor: pointer;
                }
                
                > i {
                    font-size: 1.5rem;
                }
            }
        }

        .menu-slide-left {
            animation-name: menu-slide-to-left;
            animation-duration: 1s;
            animation-timing-function: cubic-bezier(.25,.46,.45,.94);

            @keyframes menu-slide-to-left {
                0%    { left:0px; }
                100%  { left:-500px; }
            }
        }

        .menu-slide-right {
            animation-name: menu-slide-to-right;
            animation-duration: .5s;
            animation-timing-function: linear;

            @keyframes menu-slide-to-right {
                0%    { left:-500px; }
                100%  { left:0px; }
            }
        }

        .menu-wrapper {
            display: none;
            top: 0;
            left: 0;
            width: 100vw;
            pointer-events: auto;
            transition: right .5s ease, left .5s ease;

            .menu-toggle {
                position: fixed;
                z-index: 0;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background-color: $color-grey-3;
                transition: opacity .6s;
                visibility: hidden;
                opacity: 0;

                &:hover {
                    cursor: pointer;
                }
            }

            .main-nav {

                position: absolute;
                display: flex;
                z-index: 100 !important;
                top: 0;
                left: 0;
                height: 100vh;
                background-color: $color-grey-24;
                line-height: 3rem;
                overflow: initial;

                .menu {
                    list-style-type: none;
                    flex-grow: 1;
                    flex-shrink: 1;
                    overflow: auto;
                    display: block;
                    padding: 0 5vw 2rem 5vw;
                    margin: 0;
                    color: $color-grey-5;
                    font-weight: bold;
                    width: 17.5rem;

                    > .menu-toggle {
                        position: fixed;
                        z-index: 5;
                        top: 1rem;
                        left: 2rem;
                        width: 2rem;
                        height: 2rem;
                        visibility: hidden;
                        background: none;
                        color: $color-grey-1;
                        > i {
                            width: 2rem;
                            font-size: 1.4rem;
                            line-height: 6rem;
                        }
                    }


                    .logo {
                        margin-left: auto;
                        margin-right: auto;
                        width: min-content;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        color: $color-grey-9;
                        text-decoration: none;
                
                        h1 {
                            font-size: 2rem;
                            color: $color-primary;
                            border-bottom: 2.5px solid $color-grey-9;
                            line-height: 2.5rem;

                            span {
                                color: $color-grey-9;
                            }
                        }

                        h2 {
                            margin-top: .75rem;
                        }
                    }

                    > li {
                        border-left: none !important;
                        
                        ul {
                            margin-left: 0;
                        }
                    }

                    li {
                        border-left: 2px solid $color-grey-8;

                        .li-header {
                            a {
                                color: $color-grey-8;
                                display: block !important;
                                position: relative;
                                padding-right: 1rem;
                                padding-left: 1rem;
                                border-radius: 5px;
                                width: 10rem;
                                animation: box-shadow .3s ease;
    
                                &:hover {
                                    background-color: $color-grey-23;
                                    color: $color-grey-2;
                                }
                            }
                        }
                    }

                    div {
                        display: flex;

                        .expand-menu {
                            background: none;
                            border: none;

                            &:hover {
                                cursor: pointer;
                            }
                            
                            > i {
                                font-size: .9rem;
                                color: $color-grey-5;

                                &:hover {
                                    color: $color-grey-1;
                                }    
                            }
                        }
                    }

                    .expand {
                        animation: expand ease-in-out forwards;
                        transform-origin: top center;
                        overflow-y: hidden;
                    }

                    .reduce {
                        animation: reduce ease-in-out forwards;
                        transform-origin: top center;
                        overflow-y: hidden;
                    }

                    ul {
                        list-style-type: none;
                        padding-left: 1rem;
                        overflow: auto;
                        display: block;
                    }

                    .is-reduced {
                        display: none;
                        visibility: hidden;
                    }

                    hr {
                        margin: 3rem auto 1.5rem auto;
                    }

                    > li > .login-section {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        padding: 0;
                        height: 10rem;
                    }

                    .last-li {
                        height: 2rem;
                    }
                }
            }

            .primary, .secondary {
                width: 100%;
            }
        }

        .vertical-line {
            width: 1px;
            height: 100%;
            float: left;
            margin: auto 1rem;
        }

        .grow-down {
            animation: growDown 300ms ease-in-out forwards;
            transform-origin: top center;
            @keyframes growDown {
                0% {transform: scaleY(0)}
                80% {transform: scaleY(1.1)}
                100% {transform: scaleY(1)}
            }
        }

        > .wrapper {
            &:hover {
                > .dropdown-container {
                    display: initial;
                    visibility: initial;
                }
            }

            > .toggle {
                padding: .8rem;
            }

            > .dropdown-container {
                display: none;
                visibility: hidden;
                padding: 1rem;
                position: absolute;
                z-index: 1;

                > .dropdown {
                    display: flex;
                    padding: 1rem;
                    border-radius: 3px;
                    background-color: $color-grey-24;
                    box-shadow: 0 1px 1px rgba(0,0,0,0.11), 
                                0 1px 2px rgba(0,0,0,0.11), 
                                0 4px 4px rgba(0,0,0,0.11), 
                                0 6px 8px rgba(0,0,0,0.11);

                    &::before {
                        content: "";
                        position: absolute;
                        width: 18px;
                        height: 18px;
                        margin-top: -1.5rem;
                        transform-origin: center;
                        transform: rotate(45deg);
                        z-index: -1;
                        background-color: $color-grey-24;
                        box-shadow: 0 0 1px rgba(0,0,0,0.08), 
                                    0 0 2px rgba(0,0,0,0.08), 
                                    0 0 4px rgba(0,0,0,0.08);
                    }

                }
            }

            ul {
                list-style: none;
            }
        }

        > .articles {
            > .dropdown-container {
                margin-left: -10rem;
                > .dropdown {
                    flex-direction: row;
                    &::before {
                        margin-left: 11.5rem;
                    }

                    > li {
                        padding: 0 2.2rem;
                        border-right: 1px solid $color-grey-21;

                        > a {
                            font-weight: 800;
                        }
                        > ul {
                            padding: .5rem 0 0 0;
                            font-size: .85rem;
                            line-height: 1.4rem;
                        }
                        &:first-child {
                            padding-left: 1rem;
                        }
                        &:last-child {
                            padding-right: 1rem;
                            border-right: none;
                        }
                        a {
                            transition: color .2s;
                            &:hover {
                                color: $color-primary;
                            }
                        }
                    }
                }
            }
        }

        > .about {
            > .dropdown-container {
                margin-left: -4rem;
                > .dropdown {
                    flex-direction: column;
                    &::before {
                        margin-left: 5rem;
                    }
                    a {
                        font-weight: 800;
                        font-size: .95rem;
                        line-height: 1.4rem;
                        transition: color .2s;
                        &:hover {
                            color: $color-primary;
                        }
                    }
                }
            }
        }

        > .login {
            > .dropdown-container {
                margin-left: -9rem;
                > .dropdown {
                    flex-direction: row;
                    &::before {
                        margin-left: 8rem;
                    }

                    > li:first-child {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}
.is-hidden-mobile {
    display: none;
    visibility: hidden;
}

@media only screen and (min-width: $max-tablet-width) {
    .is-hidden-mobile {
        display: initial;
        visibility: initial;
    }
    .is-hidden-desktop {
        display: none;
        visibility: hidden;
    }
}
