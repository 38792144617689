body {
    overflow-x: hidden;
}
html, body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    width: 100%;
    color: $color-grey-8;
    text-align: justify;

    a {
        text-decoration: none;
        color: $color-link;
    }

    article {
        padding: 1.5rem;

        .article-head {
            h1 {
                font-size: 2.5rem !important;
                text-align: left;
                text-transform: uppercase;
            }

            h3 {
                margin-top: 2rem;
                font-size: 1rem;
                font-style: italic;
            }
        }

        table {
            border-collapse: collapse;
            border: 1px solid black;

            td {
                border: 1px solid black;
                padding: .5em .5em .5em .2em;
            }

            th {
                border: 1px solid black;
                padding: .5rem; 
            }
        }

        h1 {
            font-size: 1.75rem;
            margin-bottom: 1.25rem;
            margin-top: 1.75rem;
        }

        h2 {
            font-size: 1.625rem;
            margin-bottom: 1.25rem;
            margin-top: 1.75rem;
        }

        h3 {
            font-size: 1.5rem;
            margin-bottom: 1.25rem;
            margin-top: 1.75rem;
        }

        h4 {
            font-size: 1.375rem;
            margin-bottom: 1rem;
            margin-top: 1.25rem;
        }

        h5 {
            font-size: 1.25rem;
            margin-bottom: 1rem;
            margin-top: 1.25rem;
        }

        h6 {
            font-size: 1.125rem;
            margin-bottom: 1rem;
            margin-top: 1.25rem;
        }

        p {
            margin: 1.25rem 0rem 1.25rem 0rem;
            font-size: .9rem;
            line-height: 1rem;
        }

        
        code {
            overflow: scroll;
        }

        img {
            display: block;
            margin: 0 auto;
        }
        pre {
            margin-top: 1.5rem;
            overflow-y: scroll;
            font-size: 0.75rem;
        }
        
        code {
            overflow: scroll;
        }

        img {
            display: block;
            margin: 0 auto;
        }

        .content {
            margin-top: 1rem;
            margin-bottom: 3rem;
            background-color: $color-grey-23;
            border-radius: 4px;
            padding: 1rem;
            box-shadow: 0 1px 2px rgba(0,0,0,0.05), 
                        0 2px 4px rgba(0,0,0,0.05), 
                        0 4px 8px rgba(0,0,0,0.05); 
        
            .title {
                text-align: center;
                font-size: 1.2rem;
                font-weight: 600;
            }
        }

        .login {
            padding: 0 1rem;
            > ul {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                max-width: 20rem;
                padding: 0;
                > li {
                    line-height: 1.5rem;
                }
            }
        }

        .add-comment {
            width: 80vw;
            margin: 4rem auto;

            form[name=comments] {
                display: flex;
                flex-direction: column;
                textarea {
                    resize: vertical;
                    min-height: 4rem;
                    width: 78vw;
                    margin-top: 1rem;
                    padding: 1vw;
                }
                .buttons {
                    margin-top: 1rem;
                    > button[type=submit] {
                        cursor: pointer;
                        width: 100%;
                        color: $color-grey-24 !important;
                        border: 2px solid $color-grey-6;
                        background-color: $color-grey-6;
                        line-height: 2.5rem;
                        font-weight: 900;
                        transition: opacity 0.3s ease-in-out,
                                    box-shadow 0.3s ease-in-out; 
                        &:hover {
                            opacity: .95;
                            box-shadow: 0 1px 1px rgba(0,0,0,0.06), 
                                        0 2px 2px rgba(0,0,0,0.06), 
                                        0 4px 4px rgba(0,0,0,0.06), 
                                        0 8px 8px rgba(0,0,0,0.06);
                            color: $color-grey-24;
                        }
                    }
                }
            }

            .alert {
                position: fixed;
                top: 10vh;
                left: 5vw;
                z-index: 100;
                border-left: 10px solid #5cb85c;
                background: #f1f9f1;
                color: #3d8b3d;
                padding: 2rem;
                border-radius: 5px;
                animation:signup-response 3s 1;
                -webkit-animation:signup-response 3s 1;
                animation-fill-mode: forwards;
                
                animation-delay: 3s;
            
                box-shadow: 0 1px 1px rgba(0,0,0,0.11), 
                            0 2px 2px rgba(0,0,0,0.11), 
                            0 4px 4px rgba(0,0,0,0.11), 
                            0 8px 8px rgba(0,0,0,0.11), 
                            0 16px 16px rgba(0,0,0,0.11), 
                            0 32px 32px rgba(0,0,0,0.11);
            }

            @keyframes signup-response{
                from {opacity :1;}
                to {
                        opacity :0;
                        display: none;
                        visibility: hidden;
                    }
            }
        }

        .comments {
            width: 80vw;
            margin: 4rem auto 2rem auto;

            > .comment-list {
                padding: 0 1rem;

                > .comment {
                    padding-bottom: 2rem;
                    margin-bottom: 2rem;
                    border-bottom: 1px dashed $color-grey-22;

                    &:last-child {
                        margin-bottom: 0;
                        border-bottom: none;
                    }

                    > .comment-author {
                        font-size: .9rem;
                        font-weight: 900;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        display: inline;
                    }
                    
                    > .comment-date {
                        font-size: .8rem;
                        display: inline;
                        margin-left: .2rem;
                        color: $color-grey-10;
                    }

                    > .comment-text {
                        margin-top: .2rem;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
    .primary-container > .primary, .secondary-container > .secondary {
        white-space: nowrap;
    }

    
    .secondary-container > .secondary {
        min-width: 5rem;
        cursor: pointer;
        display: inline-flex;
        padding: .5rem 1rem;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        text-decoration: none;
        border: 2px solid $color-primary-leftest;
        font-weight: 900;
        color: $color-primary-left !important;
        transition: border .3s ease-in-out, 
                    background-color .3s ease-in-out,
                    opacity .3s ease-in-out,
                    color .6s ease; 
        text-align: center;

        &:hover {
            border: 2px solid $color-primary-leftest;
            background-color: $color-primary-leftest;
            opacity: .7;
            color: $color-grey-24 !important;
        }
    }

    .primary-container > .primary {
        cursor: pointer;
        display: inline-flex;
        padding: .5rem 1rem;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        text-decoration: none;
        border: 2px solid $color-primary-leftest;
        background-color: $color-primary-leftest;
        font-weight: 900;
        border-radius: 3px;
        color: $color-grey-24 !important;
        transition: box-shadow 0.3s ease-in-out; 
        text-align: center;
        &:hover {
            box-shadow: 0 1px 1px rgba(0,0,0,0.06), 
                        0 2px 2px rgba(0,0,0,0.06), 
                        0 4px 4px rgba(0,0,0,0.06), 
                        0 8px 8px rgba(0,0,0,0.06);
            color: $color-grey-24;
        }
    }

    .related-articles {
        > hr {
            margin-bottom: 2rem;
        }
        padding-top: 2rem;
        > h4 {
            width: 80vw;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .back-link {
        margin-bottom: 2rem;

        i {
            margin-right: 1rem;
        }
    }
}

@media only screen and (min-width: $max-tablet-width) {
    .article, #body {
        width: 94vw;
        margin: 3vw;
        padding: 0;

        .alert {
            left: none;
            right: 5vw;
        }
    }
}