.footer {
    background-color: $color-primary-leftest;
    color: $color-grey-4;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    text-align: center;

    a {
        color: $color-grey-4;
        
        &:hover {
            color: $color-grey-0;
        }
    }

    hr {
        color: $color-grey-4;
        width: 30vw;
    }

    i {
        font-size: 2rem;
    }

    .links-section {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 2rem;
    }

    .privacy-policy {
        margin-top: 2rem;
        font-weight: 800;
        &:hover {
            cursor: pointer;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .footer {
        align-items: center;

        > div {
            max-width: 60vw;
            min-width: 30vw;
        }
    }
}
