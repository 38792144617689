$tule-height : 9rem;

article {

    .article-list {
        margin-top: 2rem;
        line-height: 1.5rem;

        .article-tule {
            display: flex;
            flex-direction: row;
            position: absolute;
            left: 5vw;
            right: 5vw;
            height: $tule-height;
            padding: 1rem;
            border-radius: 6px;
            box-shadow: 0 0 1px rgba(0,0,0,0.05), 
                        0 0 2px rgba(0,0,0,0.05),
                        0 0 4px rgba(0,0,0,0.05), 
                        0 1px 1px rgba(0,0,0,0.05), 
                        0 2px 2px rgba(0,0,0,0.05), 
                        0 4px 4px rgba(0,0,0,0.05), 
                        0 8px 8px rgba(0,0,0,0.05);
            color: $color-grey-6;
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 6px;
                box-shadow: 0 0 1px rgba(0,0,0,0.11), 
                            0 0 2px rgba(0,0,0,0.11),
                            0 0 4px rgba(0,0,0,0.11), 
                            0 0 8px rgba(0,0,0,0.11), 
                            0 1px 1px rgba(0,0,0,0.11), 
                            0 2px 2px rgba(0,0,0,0.11), 
                            0 4px 4px rgba(0,0,0,0.11), 
                            0 8px 8px rgba(0,0,0,0.11), 
                            0 16px 16px rgba(0,0,0,0.11);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;   
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }

            > .tule-image {
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 35%;
                max-width: 300px;

                > img {
                    max-width: 20vw;
                    max-height: $tule-height - 1.4rem;
                }
            }
            
            > .article-tule-informations {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                line-height: 1rem;
                text-align: center;
                width: 100%;
                margin-left: 1rem;

                .article-tule-description {
                    line-height: initial;
                    font-size: 1.1em;
                    font-weight: 600;
                    color: $color-grey-3;
                }

                .article-tule-title {
                    font-weight: 600;
                    margin: 0;
                }

                > .article-tule-secondary-informations {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    font-size: .9rem;

                    > .article-tule-date {
                        display: none;
                        visibility: hidden;
                    }

                    > .article-tule-author {
                        max-width: 20rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .tule-anchor {
            content: "";
            z-index: -1;
            height: $tule-height + 2rem + 2rem;
            font-size: 0;
            line-height: 0;
        }
    }

    > ul {
        padding-left: 0;
    }

    ul {
        list-style-type: none;
    }

    > .theme-list {
        > .theme {
            text-align: center;

            > .theme-title {
                display: inline-block;
                padding: 2rem 0;
                
                color: $color-grey-2;
                font-weight: 600;
                width: -moz-available;
                border-radius: 8px;
                transition: color .3s;



                > .theme-image {
                    width: 80vw;
                    margin: auto;
                }
            }
            &:hover > .theme-title {
                color: $color-link-hover;
            }
        }
    }

    .sub-theme-list {
        > .sub-theme {
            > .sub-theme-title {
                display: flex;
                flex-direction: row;
                color: $color-grey-10;
                font-weight: 600;
                transition: background-color .2s ease-in;

                > a {
                    white-space: nowrap;
                    transition: color .2s;
                    padding: 1.5rem;
                    &:hover {
                        color: $color-grey-15;
                    }
                }
                > .toggle-up, > .toggle-down {
                    transition: color .2s;
                    padding: 1.5rem;
                    &:hover {
                        color: $color-grey-2;
                    }
                }

                &:hover {
                    cursor: pointer;
                    background-color: $color-grey-23;
                }

                > .toggle-up, > .toggle-down {
                    width: 100%;
                    text-align: right;
                }
            }
        }

        > .reduced {
            > .sub-theme-title > .toggle-up {
                display: none;
                visibility: hidden;
            }
            > .article-list {
                height: 0;
                display: none;
                visibility: hidden;
            }
        }
        > .expanded {
            > .sub-theme-title > .toggle-down {
                display: none;
                visibility: hidden;
            }
        }
    }

    .related-articles {
        border-top: 1px solid $color-grey-8;
        padding-top: 1rem;
        margin-top: 2.5rem;

        >  h4 {
            margin-left: 2rem;
        }
        > .article-list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: nowrap;
            overflow-x: scroll;
            padding: 1.2rem 0;
            background-color: $color-grey-23;
            border-radius: 3px;
            > li {
                > .article-tule {
                    position: relative;
                    right: initial;
                    left: initial;
                    width: 39vw;
                    min-width: 22rem;
                    margin: 0 .75rem;
                    background-color: $color-grey-24;
                }
                > .tule-anchor {
                    display: none;
                    visibility: hidden;
                }
            }
        }
    }
}

@media only screen and (min-width: $max-tablet-width) {
    .article-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        li {

            &:nth-child(even) {

                > .article-tule {
                    right: 5vw;
                    left: auto;
                }
            }

            .article-tule {
                position: absolute;
                width: 39vw;

                > .article-tule-secondary-informations > .article-tule-date {
                        display: initial;
                        visibility: hidden;
                }
            }

            > .tule-anchor {
                width: 40vw;
            }
        }
    }
    article {
   
        > .theme-list {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            > .theme {
                width: 45vw;
                border-radius: 5px;
                transition: box-shadow .3s;

                &:hover {
                    box-shadow: 0 1px 1px rgba(0,0,0,0.11), 
                    0 2px 2px rgba(0,0,0,0.11), 
                    0 4px 4px rgba(0,0,0,0.11), 
                    0 6px 8px rgba(0,0,0,0.11),
                    0 8px 16px rgba(0,0,0,0.11);
                }

                > .theme-title {
                    > .theme-image {
                        height: 19rem;
                        width: auto;
                        max-width: 40vw;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: $max-mobile-width) {
    .article-tule-date {
        display: initial !important;
        visibility: visible !important;
    }
}