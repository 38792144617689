@font-face {
    font-family: 'Montserrat';
    src: url('/user/themes/university-theme/assets/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('/user/themes/university-theme/assets/fonts/Montserrat-Regular.woff') format('woff'),
        url('/user/themes/university-theme/assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Karla';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqaE0lK.woff2) format('woff2'), 
        url(https://fonts.gstatic.com/s/karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqaE0lM.woff) format('woff'),
        url(https://fonts.gstatic.com/s/karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqaE0lP.ttf) format('truetype');
}
  
html {
    font-family: 'Montserrat', 'Karla', Arial, Helvetica, sans-serif;
}
