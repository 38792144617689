#home-body {
    height: 70vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .fond {
        position: fixed;
        z-index: -1;
    }

    .home-logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $color-grey-10;
        text-decoration: none;

        h1 {
            color: $color-primary;
            border-bottom: 1.875px solid $color-grey-10;
            margin-bottom: -.75rem;
            font-size: 3.75rem;

            span {
                color: $color-grey-10;
            }
        }
        h2 {
            font-size: 2.25rem;
        }
    }

    .home-search {
        margin-top: 2rem;
        width: 21rem;

        > form {
            > input {
                width: 16rem;
                line-height: 2rem;
                border-radius: 5px;
                padding-left: 1rem;
            }
    
            > .search-submit {
                vertical-align: middle;
                border: none;
                background: none;
                
                > i {
                    font-size: 1.7rem;
                    color: $color-grey-7;
                    margin-top: -6px;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
@media only screen and (min-width: $max-mobile-width) {
    #home-body {
        .home-logo {
            h1 {
                color: $color-primary;
                border-bottom: 2.5px solid $color-grey-10;
                margin-bottom: -1rem;
                font-size: 5rem;
            }
            h2 {
                font-size: 2rem;
            }
        }
        .home-search {
            width: 31rem;

            > form {
                > input {
                    width: 26rem;
                }
            }
        }
    }
}