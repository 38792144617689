.home-header {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 10px rgba(0,0,0,0.10), 
                0 2px 20px rgba(0,0,0,0.10), 
                0 4px 40px rgba(0,0,0,0.10), 
                0 8px 80px rgba(0,0,0,0.10);
    
    border-top: 3px solid $color-primary;
    max-height: 16vh;

    > .header-pages-list {
        padding: 0;
        width: 20rem;
        height: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;

        a {
            color: $color-grey-8;
            transition: all .2s linear;

            &:hover {
                color: $color-primary;
            }
        }

        > li {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .grow-down {
            animation: growDown 300ms ease-in-out forwards;
            transform-origin: bottom center;
            @keyframes growDown {
                0% {transform: scaleY(0)}
                80% {transform: scaleY(1.1)}
                100% {transform: scaleY(1)}
            }
        }

        > .wrapper {
            &:hover {
                > .dropdown-container {
                    display: initial !important;
                    visibility: initial !important; 
                }

                > .toggle {
                    > .toggle-up {
                        display: none;
                        visibility: hidden;
                    }
                    > .toggle-down {
                        display: initial;
                        visibility: initial;
                    }
                }
            }

            > .toggle {
                display: flex;
                flex-direction: row;
                align-items: center;

                > i {
                    margin-left: .6rem;
                    padding: .6rem;
                    transition: color .3s;
                    &:hover {
                        color: $color-primary;
                        cursor: pointer;
                    }
                }

                > .toggle-down {
                    display: none;
                    visibility: hidden;
                }
                > .toggle-up {
                    display: initial;
                    visibility: initial;
                }
            }

            > .dropdown-container {
                display: none;
                visibility: hidden;
                padding: 1rem;
                position: absolute;
                z-index: 1;
                bottom: 3.8rem;

                > .dropdown {
                    display: flex;
                    padding: 1rem;
                    border-radius: 3px;
                    background-color: $color-grey-24;
                    box-shadow: 0 -1px 1px rgba(0,0,0,0.11), 
                                0 -2px 2px rgba(0,0,0,0.11),
                                0 0 2px rgba(0,0,0,0.08); 

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 6px;
                        width: 18px;
                        height: 18px;
                        transform-origin: center;
                        transform: rotate(45deg);
                        z-index: -1;
                        z-index: 1;
                        box-shadow: 1px 1px 1px rgba(0,0,0,0.07), 
                                    2px 2px 2px rgba(0,0,0,0.07); 
                    }

                }
            }

            ul {
                list-style: none;
            }
        }

        > .articles {
            > .dropdown-container {
                left: .2rem;

                > .dropdown {
                    flex-direction: row;
                    &::after {
                        margin-left: 11.5rem;
                        margin-left: 3rem;
                    }

                    > li {
                        padding: 0 2.2rem;
                        border-right: 1px solid $color-grey-21;

                        > a {
                            font-weight: 800;
                        }
                        > ul {
                            padding: .5rem 0 0 0;
                            font-size: .85rem;
                            line-height: 1.4rem;
                        }
                        &:first-child {
                            padding-left: 1rem;
                        }
                        &:last-child {
                            padding-right: 1rem;
                            border-right: none;
                        }
                        a {
                            transition: color .2s;
                            &:hover {
                                color: $color-primary;
                            }
                        }
                    }
                }
            }
        }

        > .about {
            > .dropdown-container {
                margin-left: -4rem;
                > .dropdown {
                    flex-direction: column;
                    &::after {
                        margin-left: 5rem;
                    }
                    a {
                        transition: color .2s;
                        &:hover {
                            color: $color-primary;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: $max-mobile-width) {
    .home-header > .header-pages-list > .articles > .dropdown-container {
        margin-left: -8rem;
        left: initial;
        > .dropdown::after {
            margin-left: 11.5rem;
        }
    }
}
@media only screen and (min-width: $max-tablet-width) {
    .home-header {
        .header-pages-list {
            width: 27rem;
        }
    }
}